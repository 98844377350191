<template>
    <div class="mb-5 align-items-center">
        <ValidationObserver ref="storeForm">
            <div class="row">
                <div class="col-12">
                    <ValidationProvider name="semester_id" rules="required"
                                        v-slot="{ valid, errors }">
                        <b-form-group :label="$t('semester')">
                            <semesters-selectbox :validate-error="errors[0]" v-model="form.semester_id"/>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12">
                    <ValidationProvider name="type" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('type')">
                            <parameter-selectbox code="semester_dates" :validate-error="errors[0]"
                                                 v-model="form.type"/>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12">
                    <ValidationProvider name="start_date" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('start_date')">
                            <select-date :is-time="true" v-model="form.start_date" :validation-error="errors[0]" :time="true"/>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12">
                    <ValidationProvider name="end_date" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('end_date')">
                            <select-date :is-time="true" v-model="form.end_date" :validation-error="errors[0]" :time="true"/>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12">
                    <ValidationProvider name="class" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('class')">
                            <multi-parameter-selectbox code="classes" :validate-error="errors[0]" v-model="form.class"/>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12">
                    <ValidationProvider name="level" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('level')">
                            <multi-parameter-selectbox code="program_levels"
                                                       :multiple="true"
                                                       :validate-error="errors[0]"
                                                       v-model="form.level"/>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12">
                    <ValidationProvider name="faculty" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox-multiple :validate-error="errors[0]" v-model="form.faculty"/>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12">
                    <ValidationProvider name="student_number" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('student_numbers')">
                            <lined-textarea v-model="form.student_number"
                                            :nowrap="false"
                                            :disabled="false"
                                            :styles="{ height: '200px', resize: 'both' }"
                                            :validateError="errors[0]">
                            </lined-textarea>
                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                        </b-form-group>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
        <div class="col-12 mt-3 d-flex p-0">
            <b-button @click="update" type="button" variant="primary" class="btn-lg mr-2">
                {{ $t("update") | toUpperCase }}
            </b-button>
        </div>
    </div>
</template>

<script>


import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox.vue";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox.vue";
import MultiParameterSelectbox from "@/components/interactive-fields/MultiParameterSelectbox.vue";
import FacultySelectboxMultiple from "@/components/interactive-fields/FacultySelectboxMultiple.vue";
import SelectDate from "@/components/interactive-fields/SelectDate.vue";
import LinedTextarea from "@/components/elements/LinedTextarea"

import {ValidationObserver, ValidationProvider} from "vee-validate";

import SemesterDateService from "@/services/SemesterDateService";

export default {
    components:{
        ValidationProvider,
        ValidationObserver,

        SemestersSelectbox,
        ParameterSelectbox,
        MultiParameterSelectbox,
        FacultySelectboxMultiple,
        SelectDate,
        LinedTextarea
    },
    props:{
        id:{
            type:Number,
            default:null
        }
    },
    data() {
        return {
            form: {},
        }
    },
    created() {
        this.show()
    },
    methods: {
        show(){
            SemesterDateService.get(this.id)
                .then((response) => {
                    this.form = response.data.data;
                    if(this.form.student_number) {
                        this.form.student_number = this.form.student_number.join("\n")
                    }
                })
                .catch((error) => {
                    if (error.data.message) {
                        this.$toast.error(this.$t("api." + error.data.message));
                    }
                });
        },
        async update() {
            if(this.form.student_number) {
                this.form.student_number = this.form.student_number.split(/\n/)
            } else {
                delete this.form.student_number
            }

                let formData = {...this.form};

                SemesterDateService.update(this.id, formData)
                    .then((response) => {
                        this.$emit('success')
                        this.$toast.success(this.$t("api." + response.data.message));
                    })
                    .catch((error) => {
                        this.showErrors(error,this.$refs.storeForm)
                    })
        }
    }
}
</script>
